/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;
@import "./variables.scss";
@import "~bootstrap/dist/css/bootstrap.css";
@import "./assets/animated.scss";
@import '@angular/material/theming';
@import "../node_modules/angular-calendar/css/angular-calendar.css";


html, body { height: 100%; font-family: 'Raleway', sans-serif;}
::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: $primary-color;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }

$mat-myapp-primary: (
    50: #e5f6fe,
    100: #bfe9fc,
    200: #94dbfb,
    300: #69ccf9,
    400: #49c1f7,
    500: #29b6f6,
    600: #24aff5,
    700: #1fa6f3,
    800: #199ef2,
    900: #0f8eef,
    A100: #ffffff,
    A200: #e8f4ff,
    A400: #b5dcff,
    A700: #9cd1ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$mat-myapp-accent: (
    50: #e5f6fe,
    100: #bfe9fc,
    200: #94dbfb,
    300: #69ccf9,
    400: #49c1f7,
    500: #29b6f6,
    600: #24aff5,
    700: #1fa6f3,
    800: #199ef2,
    900: #0f8eef,
    A100: #ffffff,
    A200: #e8f4ff,
    A400: #b5dcff,
    A700: #9cd1ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$primary: mat.define-palette($mat-myapp-primary);
$accent: mat.define-palette($mat-myapp-accent);

$theme: mat.define-light-theme($primary, $accent);

@include mat.all-legacy-component-themes($theme);

.txt-main{
    color : $primary-color!important;
}

.bg-main{
    background-color : #9eade5!important;
}

.full{
    min-height: 100vh;
}

.full-scroll{
    height: 100vh;
    overflow: auto;
}

.full-width{
    width: 100%;
}

.fixed{
    position: fixed;
    z-index: 999;
}

.mat-drawer-inner-container{
    overflow: hidden!important;
}

.center-flex{
    display: flex;
    justify-content: center;
    align-items: center;
}

.cursor{
    cursor: pointer;
}

.back-btn{
    width: 100px;
}

.info-block{
    border-left: 6px solid $primary-color;
    border-radius: 6px;
}

button:disabled {
    cursor: not-allowed!important;
}

a{
    color: gray!important;
}

.full-video{
    width: 100%!important;
    height: 100%!important;
}

.call-button{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: none;
    background-color: $primary-color!important;
}

.call-button{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    border: none;
    background-color: $primary-color!important;
}

.raleway{
    font-family: 'Raleway', sans-serif;
}

.semi-bolt{
    font-weight: 600;
}

.loading{
    position: absolute;
    top: 0;
    background: rgba(255,255,255,.7);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: 9999;
}
